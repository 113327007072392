import states from "./service.state";
import getters from "./service.getter";
import actions from "./service.action";
import mutations from "./service.mutation";

const serviceModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default serviceModule;
