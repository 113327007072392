import Vue from "vue";
import ApiService from "../../../core/services/api.service";

const actions = {
  createAnnonce(context) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`ad/create`)
          .then(response => {
            resolve(response.data);
            context.commit("setFormAnnonce", response.data.form);
            context.commit("setAnnonceOptions", response.data.option);
            context.commit("setAnnonceTags", response.data.tags);
            context.commit("setAnnonceTypes", response.data.types);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  editAnnonce(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`ad/${payload}/edit`)
          .then(response => {
            resolve(response.data);
            context.commit("setFormAnnonce", response.data.form);
            context.commit("setAnnonceOptions", response.data.option);
            context.commit("setAnnonceTags", response.data.tags);
            context.commit("setAnnonceTypes", response.data.types);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  storeAnnonce(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.post(`ad`, payload.form, payload.config)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  updateAnnonce(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.post(`ad/${payload.id}`, payload.form, payload.config)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  }
};

export default actions;
