import htmlClass from "@/store/htmlclass.module";
import config from "@/store/config.module";
import breadcrumbs from "@/store/breadcrumbs.module";
import auth from "../auth.module";
import adModule from "./ad/ad.module";
import userModule from "./user/user.module";
import townModule from "./town/town.module";
import serviceModule from "./service/service.module";
import roleModule from "./role/role.module";
import publicityModule from "./publicity/publicity.module";
import productModule from "./product/product.module";
import postModule from "./post/post.module";
import partnerModule from "./partner/partner.module";
import districtModule from "./district/district.module";
import contactModule from "./contact/contact.module";
import categoryModule from "./category/category.module";
import visitModule from "./visit/visit.module";

const modules = {
  auth,
  htmlClass,
  config,
  breadcrumbs,
  adModule,
  userModule,
  townModule,
  serviceModule,
  roleModule,
  publicityModule,
  productModule,
  postModule,
  partnerModule,
  districtModule,
  contactModule,
  categoryModule,
  visitModule
};

export default modules;
