import states from "./category.action";
import getters from "./category.getter";
import actions from "./category.action";
import mutations from "./category.mutation";

const categoryModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default categoryModule;
