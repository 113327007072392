import states from "./town.state";
import getters from "./town.getter";
import actions from "./town.action";
import mutations from "./town.mutation";

const townModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default townModule;
