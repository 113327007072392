import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import axios from "axios";

export default {
  namespace: true,
  state: {
    authenticated: false,
    error: null,
    token: JwtService.getToken() || null,
    user: null
  },
  getters: {
    authenticated(state) {
      return state.token !== null;
    },
    user(state) {
      return state.user;
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      state.authenticated = true;
    },
    REMOVE_TOKEN(state) {
      state.token = null;
      state.authenticated = false;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  actions: {
    LOGIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("login", payload)
          .then(response => {
            const token = response.data.access_token;
            JwtService.saveToken(token);
            context.commit("SET_TOKEN", token);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    USER(context) {
      if (context.getters.authenticated) {
        return new Promise((resolve, reject) => {
          axios
            .post("me")
            .then(response => {
              context.commit("SET_USER", response.data.data);
              resolve(response);
            })
            .catch(error => {
              JwtService.destroyToken();
              context.commit("REMOVE_TOKEN");
              reject(error);
            });
        });
      }
    },
    LOGOUT(context) {
      if (context.getters.authenticated) {
        return new Promise((resolve, reject) => {
          ApiService.setHeader();
          axios
            .get("logout")
            .then(response => {
              JwtService.destroyToken();
              context.commit("REMOVE_TOKEN");
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    }
  }
};
