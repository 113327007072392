const getters = {
  getFormAnnonce(state) {
    return state.formAnnonce;
  },
  getAnnonceOptions(state) {
    return state.annonceOptions;
  },
  getAnnonceTags(state) {
    return state.annonceTags;
  },
  getAnnonceTypes(state) {
    return state.annonceTypes;
  },
  getAnnonceUsers(state) {
    return state.annonceUsers;
  }
};

export default getters;
