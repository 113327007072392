import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";
import "./filters";
import "./mixins";
import "./directives";

// import moment from "moment";
import ImageUploader from "vue-image-upload-resize";

import ApiService from "@/core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/store/config.module";

// Vue 3rd party plugins
// import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
ApiService.init();
ApiService.interceptRequest();
ApiService.interceptResponse();
Vue.use(ImageUploader);
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

store.dispatch(RESET_LAYOUT_CONFIG);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.authenticated) {
      next({
        name: "Login"
      });
    } else {
      ApiService.setHeader();
      store
        .dispatch("USER")
        .then(response => {
          if (
            response.data.data.type_compte === "interne" &&
            (response.data.data.roles.length !== 0 ||
              response.data.data.is_admin === 1) &&
            response.data.data.status === 1
          ) {
            next();
          } else {
            store
              .dispatch("LOGOUT")
              .then(() => {
                next({ name: "forbidden" });
              })
              .catch(error => {
                throw new Error(`[TOGOHOME] ${error}`);
              });
          }
        })
        .catch(error => {
          throw new Error(`[TOGOHOME] ${error}`);
        });
    }
  } else if (store.getters.authenticated) {
    ApiService.setHeader();
    store.dispatch("USER").then(response => {
      if (response.data.data.type_compte === "interne") {
        next();
      } else {
        store
          .dispatch("LOGOUT")
          .then(() => {
            next({ name: "forbidden" });
          })
          .catch(error => {
            throw new Error(`[TOGOHOME] ${error}`);
          });
      }
    });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
