import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard"),
        meta: {
          requiresAuth: true,
          title: "Tableau de bord"
        }
      },

      // ############################### BEGIN ROUTE MODULE AD ############################################

      {
        path: "/liste-des-annonces",
        name: "ads.list",
        component: () => import("@/views/pages/ad/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des annonces"
        }
      },
      {
        path: "/liste-des-annonces/location",
        name: "rent.list",
        component: () => import("@/views/pages/ad/rent/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des annonces locations"
        }
      },
      {
        path: "/liste-des-annonces/vente",
        name: "sell.list",
        component: () => import("@/views/pages/ad/sell/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des annonces ventes"
        }
      },

      {
        path: "/liste-des-annonces/rejectees",
        name: "notvalidated.list",
        component: () => import("@/views/pages/ad/notvalid/NotValid"),
        meta: {
          requiresAuth: true,
          title: "Liste des annonces non validé(e)s"
        }
      },
      {
        path: "/liste-des-annonces/attente",
        name: "notyetvalidated.list",
        component: () => import("@/views/pages/ad/validnotyet/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des annonces en attente"
        }
      },

      {
        path: "/annonce/ajouter-une-annonce",
        name: "ad.new",
        component: () => import("@/views/pages/ad/Form"),
        meta: {
          requiresAuth: true,
          back: "/liste-des-annonces",
          title: "Ajouter une annonce",
          mode: "create"
        }
      },
      {
        path: "/modifier-une-annonce/:id/edition",
        name: "ad.edit",
        component: () => import("@/views/pages/ad/Form"),
        meta: {
          requiresAuth: true,
          back: "/liste-des-annonces",
          title: "Modifier une annonce",
          mode: "update"
        }
      },
      {
        path: "/voir-une-annonce/:id",
        name: "ad.show",
        component: () => import("@/views/pages/ad/Show"),
        meta: {
          requiresAuth: true,
          back: "/liste-des-annonces",
          title: "Voir une annnonce"
        }
      },
      {
        path: "/imprimer-une-annonce/:id",
        name: "ad.print",
        component: () => import("@/views/pages/ad/PrintPicture"),
        meta: {
          requiresAuth: true,
          back: "/liste-des-annonces",
          title: "Voir une annnonce"
        }
      },

      // ####################################### END ROUTE MODULE AD ######################################

      // ####################################### BEGIN ROUTE MODULE VISIT ######################################
      {
        path: "/list/visits",
        name: "visit",
        component: () => import("@/views/pages/visit/Index"),
        meta: {
          requiresAuth: true,
          back: "/list/visits",
          title: "La liste des visites"
        }
      },
      // ####################################### END ROUTE MODULE VISIT ######################################

      // ####################################### BEGIN ROUTE MODULE PARTNER ######################################

      {
        path: "/liste-des-partenaires",
        name: "partner.list",
        component: () => import("@/views/pages/partner/Index"),
        meta: {
          title: "Liste des partenaires"
        }
      },
      {
        path: "/partenaire/ajouter-partenaire",
        name: "partner.new",
        component: () => import("@/views/pages/partner/Form"),
        meta: {
          back: "/liste-des-partenaires",
          title: "Ajouter un partenaire",
          mode: "create"
        }
      },
      {
        path: "/modifier-partenaire/:id",
        name: "partner.edit",
        component: () => import("@/views/pages/partner/Form"),
        meta: {
          back: "/liste-des-partenaires",
          title: "Ajouter un modifier",
          mode: "update"
        }
      },
      {
        path: "/partenaire/:id",
        name: "partner.show",
        component: () => import("@/views/pages/partner/Show"),
        meta: {
          back: "/liste-des-partenaires",
          title: "Détail du partenaire"
        }
      },

      // ####################################### END ROUTE MODULE PARTNER ######################################

      // ####################################### BEGIN ROUTE MODULE PRODUCT ######################################

      {
        path: "/product",
        name: "product.list",
        component: () => import("@/views/pages/product/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des produits"
        }
      },
      {
        path: "/new/product",
        name: "product.new",
        component: () => import("@/views/pages/product/Form"),
        meta: {
          requiresAuth: true,
          back: "/product",
          title: "Ajouter un produit",
          mode: "create"
        }
      },
      {
        path: "/edit/product/:id",
        name: "product.edit",
        component: () => import("@/views/pages/product/Form"),
        meta: {
          requiresAuth: true,
          back: "/product",
          title: "Editer un produit",
          mode: "update"
        }
      },
      {
        path: "/product/:id",
        name: "product.show",
        component: () => import("@/views/pages/product/Show"),
        meta: {
          requiresAuth: true,
          back: "/product",
          title: "Voir un produit"
        }
      },

      // ####################################### END ROUTE MODULE PRODUCT ######################################

      // ####################################### BEGIN ROUTE MODULE BLOG ######################################

      {
        path: "/articles",
        name: "post.list",
        component: () => import("@/views/pages/post/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des articles"
        }
      },
      {
        path: "/articles-en-attente",
        name: "post.waiting.list",
        component: () => import("@/views/pages/post/WaitingPost"),
        meta: {
          requiresAuth: true,
          title: "Liste des articles"
        }
      },
      {
        path: "/articles/ajout-nouvel-article",
        name: "post.new",
        component: () => import("@/views/pages/post/Form"),
        meta: {
          requiresAuth: true,
          back: "/articles",
          title: "Ajouter un article",
          mode: "create"
        }
      },
      {
        path: "/article/:id/modification",
        name: "post.edit",
        component: () => import("@/views/pages/post/Form"),
        meta: {
          requiresAuth: true,
          back: "/articles",
          title: "Modifier un article",
          mode: "update"
        }
      },
      {
        path: "/article/:id",
        name: "post.show",
        component: () => import("@/views/pages/post/Show"),
        meta: {
          requiresAuth: true,
          back: "/articles",
          title: "Voir un article"
        }
      },

      // ####################################### END ROUTE MODULE BLOG ######################################

      // ############################### BEGIN ROUTE MODULE SERVICE ############################################

      {
        path: "/service",
        name: "service.list",
        component: () => import("@/views/pages/service/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des services"
        }
      },
      {
        path: "/new/service",
        name: "service.new",
        component: () => import("@/views/pages/service/Form"),
        meta: {
          requiresAuth: true,
          back: "/service",
          title: "Ajouter un service",
          mode: "create"
        }
      },
      {
        path: "/edit/service/:id",
        name: "service.edit",
        component: () => import("@/views/pages/service/Form"),
        meta: {
          requiresAuth: true,
          back: "/service",
          title: "Editer un service",
          mode: "update"
        }
      },
      {
        path: "/service/:id",
        name: "service.show",
        component: () => import("@/views/pages/service/Show"),
        meta: {
          requiresAuth: true,
          back: "/service",
          title: "Voir un service"
        }
      },

      {
        path: "/liste-demande-de-services",
        name: "demande.service.list",
        component: () => import("@/views/pages/service/DemandeList"),
        meta: {
          requiresAuth: true,
          title: "Liste des demandes de services"
        }
      },

      // ############################### END ROUTE MODULE SERVICE ############################################

      // ############################### BEGIN ROUTE MODULE USER ###########################################

      {
        path: "/user",
        name: "user.list",
        component: () => import("@/views/pages/user/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des utilisateurs"
        }
      },
      {
        path: "/user/internal",
        name: "internal.list",
        component: () => import("@/views/pages/user/internal/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des utilisateurs interne"
        }
      },
      {
        path: "/user/external",
        name: "external.list",
        component: () => import("@/views/pages/user/external/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des utilisateurs interne"
        }
      },
      {
        path: "/agents-immobiliers",
        name: "estate.list",
        component: () => import("@/views/pages/user/estates/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des agents immobiliers"
        }
      },
      {
        path: "/useradmin/create",
        name: "user.new",
        component: () => import("@/views/pages/user/Form"),
        meta: {
          requiresAuth: true,
          back: "/user",
          title: "Ajouter un utilisateur",
          mode: "create"
        }
      },
      {
        path: "/useradmin/:id/edit",
        name: "user.edit",
        component: () => import("@/views/pages/user/Form"),
        meta: {
          requiresAuth: true,
          back: "/user",
          title: "Modifier un utilisateur",
          mode: "update"
        }
      },
      {
        path: "/user/:id",
        name: "user.show",
        component: () => import("@/views/pages/user/Show"),
        meta: {
          requiresAuth: true,
          back: "/user",
          title: "Voir un utilisateur"
        }
      },

      // ############################### END ROUTE MODULE USER ############################################

      // ############################### BEGIN ROUTE MODULE DISTRICT ######################################################
      {
        path: "/district",
        name: "district.list",
        component: () => import("@/views/pages/district/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des quartiers"
        }
      },
      {
        path: "/new/district",
        name: "district.new",
        component: () => import("@/views/pages/district/Form"),
        meta: {
          requiresAuth: true,
          back: "/district",
          title: "Ajouter un quartier",
          mode: "create"
        }
      },
      {
        path: "/district/:id/edit",
        name: "district.edit",
        component: () => import("@/views/pages/district/Form"),
        meta: {
          requiresrequiresAuth: true,
          back: "/district",
          title: "Modifier un quartier",
          mode: "update"
        }
      },

      // ############################### END ROUTE MODULE DISTRICT ########################################################

      // ############################### BEGIN ROUTE MODULE VILLE #########################################################
      {
        path: "/town",
        name: "town.list",
        component: () => import("@/views/pages/town/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des villes"
        }
      },
      {
        path: "/new/town",
        name: "town.new",
        component: () => import("@/views/pages/town/Form"),
        meta: {
          requiresAuth: true,
          back: "/town",
          title: "Ajouter une ville",
          mode: "create"
        }
      },
      {
        path: "/town/:id/edit",
        name: "town.edit",
        component: () => import("@/views/pages/town/Form"),
        meta: {
          requiresAuth: true,
          back: "/town",
          title: "Modifier une ville",
          mode: "update"
        }
      },
      // ############################### END ROUTE MODULE VILLE ###########################################################

      // ############################### BEGIN ROUTE MODULE MESSAGE #########################################################

      {
        path: "/contact",
        name: "message.list",
        component: () => import("@/views/pages/contact/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des messages"
        }
      },

      // ############################### END ROUTE MODULE MESSAGE ###########################################################

      // ############################### BEGIN ROUTE MODULE CATEGORIES #########################################################

      {
        path: "/categories",
        name: "category.list",
        component: () => import("@/views/pages/category/Index"),
        redirect: "/categories/partner",
        meta: {
          requiresAuth: true,
          title: "Liste des catégories"
        },
        children: [
          {
            path: "/categories/partner",
            component: () => import("@/views/pages/category/partner/Index"),
            name: "category.partner"
          },
          {
            path: "/categories/post",
            name: "category.post",
            component: () => import("@/views/pages/category/post/Index")
          },
          {
            path: "/categories/typetag",
            name: "category.typeTag",
            component: () =>
              import("@/views/pages/category/typetag/TypeTagIndex")
          }
        ]
      },

      // ############################### END ROUTE MODULE CATEGORIES ###########################################################

      // ############################### BEGIN ROUTE MODULE HISTORY #########################################################

      {
        path: "/partner/delete/list",
        name: "partner.delete",
        component: () => import("@/views/pages/partner/Delete"),
        meta: {
          requiresAuth: true,
          title: "Liste des partenaire supprimés"
        }
      },

      // ############################### END ROUTE MODULE HISTORY ###########################################################

      // ############################### BEGIN ROUTE MODULE DIASPORA #########################################################

      {
        path: "/diaspora",
        name: "diaspora.list",
        component: () => import("@/views/pages/diaspora/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des annones dispora"
        }
      },
      {
        path: "/diaspora/offer/list",
        name: "offer.diaspora",
        component: () => import("@/views/pages/diaspora/List"),
        meta: {
          requiresAuth: true,
          title: "Liste des demande de service dispora"
        }
      },

      // ############################### END ROUTE MODULE DIASPORA ###########################################################

      // ############################### BEGIN ROUTE MODULE ROLES #########################################################

      {
        path: "/roles",
        name: "role.list",
        component: () => import("@/views/pages/roles/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des roles"
        }
      },
      {
        path: "/new/role",
        name: "role.new",
        component: () => import("@/views/pages/roles/Form"),
        meta: {
          requiresAuth: true,
          back: "/roles",
          title: "Ajouter un role",
          mode: "create"
        }
      },
      {
        path: "/role/:id/edit",
        name: "role.edit",
        component: () => import("@/views/pages/roles/Form"),
        meta: {
          requiresAuth: true,
          back: "/roles",
          title: "Modifier un role",
          mode: "update"
        }
      },
      // ############################### END ROUTE MODULE ROLES ###########################################################//

      // ############################### BEGIN ROUTE MODULE PERMISSIONS ###########################################################

      {
        path: "/permissions",
        name: "permission.list",
        component: () => import("@/views/pages/permissions/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des permissions"
        }
      },
      {
        path: "nouvelle-permission",
        name: "permission.new",
        component: () => import("@/views/pages/permissions/Form"),
        meta: {
          requiresAuth: true,
          back: "/permissions",
          title: "Ajouter une permission",
          mode: "create"
        }
      },
      {
        path: "/permission/:id/edit",
        name: "permission.edit",
        component: () => import("@/views/pages/permissions/Form"),
        meta: {
          requiresAuth: true,
          back: "/permissions",
          title: "Modifier une permission",
          mode: "update"
        }
      },

      // ############################### BEGIN ROUTE MODULE ATTACH PERMISSIONS ##################################################
      {
        path: "/liste-attribution-permission",
        name: "role.list.permission",
        component: () => import("@/views/pages/acl/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des rôles avec leurs permissions"
        }
      },
      {
        path: "/attacher-role/:id",
        name: "role.permission.attach",
        component: () => import("@/views/pages/acl/AddPermissionRole"),
        meta: {
          requiresAuth: true,
          back: "/liste-attribution-permission",
          title: "Modifier ACL",
          mode: "update"
        }
      },
      // ############################### END ROUTE MODULE PERMISSIONS ###########################################################

      {
        path: "/liste-des-tags",
        name: "tags.list",
        component: () => import("@/views/pages/tags/Index"),
        meta: {
          requiresAuth: true,
          title: "Liste des tags"
        }
      },

      {
        path: "/ajouter-tag",
        name: "tag.new",
        component: () => import("@/views/pages/tags/Form"),
        meta: {
          requiresAuth: true,
          title: "Ajouter un tag",
          back: "/liste-des-tags",
          mode: "create"
        }
      },

      {
        path: "/modifier-tag",
        name: "tag.edit",
        component: () => import("@/views/pages/tags/Form"),
        meta: {
          requiresAuth: true,
          title: "Modifier un tag",
          back: "/liste-des-tags",
          mode: "update"
        }
      },

      {
        path: "/liste-des-publicites",
        name: "pub.list",
        component: () => import("@/views/pages/publicite/PubList"),
        meta: {
          requiresAuth: true,
          title: "Liste des publicités"
        }
      },
      {
        path: "/publicite/ajouter-une-publicite",
        name: "pub.new",
        component: () => import("@/views/pages/publicite/Form"),
        meta: {
          requiresAuth: true,
          back: "/liste-des-publicites",
          title: "Ajouter une publicité",
          mode: "create"
        }
      },
      {
        path: "/publicite/:id/editer-publicite",
        name: "pub.edit",
        component: () => import("@/views/pages/publicite/Form"),
        meta: {
          requiresAuth: true,
          back: "/liste-des-publicites",
          title: "Modifier une publicité",
          mode: "update"
        }
      }
    ]
  },
  {
    path: "/",
    name: "requiresAuth",
    component: () => import("@/views/pages/auth/Auth"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/pages/auth/Login"),
        meta: {
          requiresAuth: false,
          title: "Login"
        }
      }
    ]
  },
  {
    path: "*",
    component: () => import("@/views/pages/errors/Error"),
    children: [
      {
        path: "*",
        component: () => import("@/views/pages/errors/NotFound")
      },
      {
        path: "/403/forbidden",
        name: "forbidden",
        component: () => import("@/views/pages/errors/Forbidden")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
