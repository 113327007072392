import states from "./post.action";
import getters from "./post.getter";
import actions from "./post.action";
import mutations from "./post.mutation";

const postModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default postModule;
