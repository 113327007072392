import Vue from "vue";
import moment from "moment";

Vue.filter("frenchFormatDate", value => {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("truncate", function(value) {
  if (!value) return "";
  value = value.toString();
  return String(value.substring(0, 50) + " ...");
});
