import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://localhost:8000/api/v1/auth/";
    Vue.axios.defaults.baseURL = "https://api.togohometg.com/api/v1/auth/";
    // Vue.axios.defaults.baseURL = "http://192.168.0.103:8000/api/v1/auth/";
  },

  interceptRequest() {
    axios.interceptors.request.use(
      config => {
        return config;
      },
      error => {
        console.log("error request");
        console.log(error);
      }
    );
  },
  interceptResponse() {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 403) {
          router.replace({ name: "forbidden" });
        }
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

    Vue.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  download(resource) {
    return Vue.axios({
      url: resource,
      method: "GET",
      responseType: "blob"
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[TOGOHOME] : ${error.response.data.errors}`);
    });
  }
};

export default ApiService;
