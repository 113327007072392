<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

/*// Main demo style scss*/
@import "assets/sass/style.vue";
@import "~intl-tel-input/build/css/intlTelInput.css";

/*// Check documentation for RTL css*/
/*// Update HTML with RTL attribute at public/index.html*/
/*@import "assets/css/style.vue.rtl";*/

.iti {
  display: block !important;
}

.iti__flag {
  background-image: url("~intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("~intl-tel-input/build/img/flags@2x.png");
  }
}
.form-inline-view {
  position: relative;

  .input-eyes {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;
    &:hover {
      color: #46a453;
    }
  }
}
</style>
<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/config.module";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
