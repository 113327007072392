import states from "./partner.action";
import getters from "./partner.getter";
import actions from "./partner.action";
import mutations from "./partner.mutation";

const partnerModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default partnerModule;
