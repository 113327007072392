import states from "./product.action";
import getters from "./product.getter";
import actions from "./product.action";
import mutations from "./product.mutation";

const productModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default productModule;
