const states = {
  formAnnonce: {
    ville: {
      id: "",
      quartiers: []
    }
  },
  annonceOptions: {
    villes: [],
    quartiers: []
  },
  annonceTags: [],
  annonceTypes: [],
  annonceUsers: []
};

export default states;
