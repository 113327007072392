import states from "./ad.state";
import mutations from "./ad.mutation";
import getters from "./ad.getter";
import actions from "./ad.action";

const adModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default adModule;
