import visitAction from "./visit.action";

const visitModule = {
  state: {
    visits: []
  },
  getters: {
    getVisits(state) {
      return state.visits;
    }
  },
  actions: visitAction,
  mutations: {
    SET_VISITS(state, visits) {
      state.visits = visits;
    }
  }
};

export default visitModule;
