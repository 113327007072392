import states from "./publicity.action";
import getters from "./publicity.getter";
import actions from "./publicity.action";
import mutations from "./publicity.mutation";

const publicityModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default publicityModule;
