import Vue from "vue";
import intlTelInput from "intl-tel-input";
import $ from "jquery";
import utilscript from "intl-tel-input/build/js/utils";

Vue.mixin({
  methods: {
    inputInternational(element) {
      return intlTelInput(element, {
        // any initialisation options go here
        initialCountry: "auto",
        geoIpLookup(success) {
          $.get("https://ipinfo.io", function() {}, "jsonp").always(function(
            resp
          ) {
            let countryCode = resp && resp.country ? resp.country : "tg";
            success(countryCode);
          });
        },
        separateDialCode: true,
        preferredCountries: ["tg", "fr"],
        utilsScript: utilscript
      });
    },
    checkPhoneNumber(number) {
      let numberPhone = number;
      let valid = numberPhone.isValidNumber();
      if (valid) {
        return numberPhone.getNumber();
      } else {
        return null;
      }
    }
  }
});
