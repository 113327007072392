const mutations = {
  setFormAnnonce(state, formAnnonce) {
    state.formAnnonce = formAnnonce;
  },
  setAnnonceOptions(state, annnonceOptions) {
    state.annonceOptions = annnonceOptions;
  },
  setAnnonceTags(state, annonceTags) {
    state.annonceTags = annonceTags;
  },
  setAnnonceTypes(state, annonceTypes) {
    state.annonceTypes = annonceTypes;
  },
  setAnnonceUsers(state, annonceUsers) {
    state.annonceUsers = annonceUsers;
  }
};

export default mutations;
