// import states from "./user.state";
import actions from "./user.action";
// import mutations from "./user.mutation";
// import getters from "./user.getter";

const userModule = {
  namespace: true,
  state: {
    userForm: {},
    userRoles: {},
    users: [],
    selectUser: {}
  },
  getters: {
    getUserForm(state) {
      return state.userForm;
    },
    getUserRoles(state) {
      return state.userRoles;
    },
    getUsers(state) {
      return state.users;
    },
    getUser(state) {
      return state.selectUser;
    }
  },
  actions,
  mutations: {
    setUserForm(state, formData) {
      state.userForm = formData;
    },
    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setUser(state, selectUser) {
      state.selectUser = selectUser;
    }
  }
};

export default userModule;
