import states from "./role.state";
import getters from "./role.getter";
import actions from "./role.action";
import mutations from "./role.mutation";

const roleModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default roleModule;
