import states from "./contact.action";
import getters from "./contact.getter";
import actions from "./contact.action";
import mutations from "./contact.mutation";

const contactModule = {
  namespace: true,
  states,
  getters,
  actions,
  mutations
};

export default contactModule;
