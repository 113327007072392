import ApiService from "../../../core/services/api.service";
import Vue from "vue";

const actions = {
  allUser(context) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`users`)
          .then(response => {
            resolve(response.data);
            context.commit("setUsers", response.data.users);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  createUser(context) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`useradmin/create`)
          .then(response => {
            resolve(response.data);
            context.commit("setUserForm", response.data.form);
            context.commit("setUserRoles", response.data.roles);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  storeUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.post(`useradmin`, payload)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  showUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`users/${payload}`)
          .then(response => {
            resolve(response.data);
            context.commit("setUser", response.data.user);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  editUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`useradmin/${payload}/edit`)
          .then(response => {
            resolve(response.data);
            context.commit("setUserForm", response.data.form);
            context.commit("setUserRoles", response.data.roles);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  updateUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.put(`useradmin/${payload.id}`, payload.form)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  deleteUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.delete(`useradmin/${payload}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
            Vue.$toast.open({
              message:
                "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
              type: "error"
            });
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  }
};

export default actions;
