import ApiService from "../../../core/services/api.service";
import Vue from "vue";

const visitAction = {
  allVisits(context) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`liste-des-demandes-de-visite`)
          .then(response => {
            resolve(response.data);
            context.commit("SET_VISITS", response.data.data);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Internet indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error"
        });
      }
    });
  },
  beforeCreate() {
    this.$store.dispatch("allVisits");
  }
};

export default visitAction;
